import HttpService from "./HttpService";

export default class OrderLabelService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Generate box label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateBoxLabel(orderId) {
    return this.httpService.put("/v1/orders/" + orderId + "/cn23-merged");
  }

  generateFirstMile(orderId) {
    return this.httpService.put("/v1/orders/" + orderId + "/first-mile-merged");
  }

  generateVolumeLabel(volumeId) {
    return this.httpService.put("/v1/volumes/" + volumeId + "/cn23");
  }

  generateVolumeFirstMile(volumeId) {
    return this.httpService.put("/v1/volumes/" + volumeId + "/first-mile");
  }

  generateShipperConfirmation(orderId) {
    return this.httpService.put(
      "/v1/orders/" + orderId + "/shipper_confirmation_pdf"
    );
  }

  generateDangerousLabel(orderId) {
    return this.httpService.put(
      "/v1/orders/" + orderId + "/dangerous_good_pdf"
    );
  }

  generateDangerousMultiple(orderIds = {}) {
    return this.httpService.post(
      "/v1/orders/multiple_dangerous_goods",
      orderIds
    );
  }
  /**
   * Generate Cn15 label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateCn15Label(orderId) {
    return this.httpService.put("/v1/orders/" + orderId + "/cn15");
  }

  /**
   * Generate commercial invoice label
   * @param volumeId
   * @param orderId
   * @returns {Promise}
   */
  generateCommercialInvoiceLabel(orderId, volumeId = {}) {
    return this.httpService.put(
      "/v1/orders/" + orderId + "/commercial-invoice",
      {
        volume_id: volumeId,
      }
    );
  }
}
