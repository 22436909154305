import HttpService from "./HttpService";

export default class VolumeReverseService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Set volume reverse
   *
   * @param data
   * @returns {Promise}
   */
  setVolumeReverse(data = {}) {
    return this.httpService.post("/v1/volume_reverses", data);
  }

  /**
   * Get volume reverse
   *
   * @param {*} id
   * @returns {Promise}
   */
  getVolumeReverse(id = null) {
    return this.httpService.get("/v1/volume_reverses/" + id);
  }

  /**
   * Get volume reverses
   *
   * @param filters
   * @returns {Promise}
   */
  getVolumeReserves(filters = {}) {
    return this.httpService.get("/v1/volume_reverses", {
      params: filters,
    });
  }
}
