import HttpService from "./HttpService";

export default class PackingSlipService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Generate packing slip label
   *
   * @param orderId
   * @returns {Promise}
   */
  generate(orderId) {
    return this.httpService.put("/v1/orders/" + orderId + "/packing-slip");
  }
}
