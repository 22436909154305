<template>
  <div>
    <table class="w-100 items-table rounded">
      <thead>
        <tr>
          <th class="heading-2 px-2" width="162px">
            {{ $t("orderViewPage.carrierOrShipper") }}
          </th>
          <th class="heading-2 px-2">
            {{ $t("status") }}
          </th>
          <th class="heading-2 px-2 min-w-200">
            {{ $t("trackingNumber") }}
          </th>
          <th class="heading-2 px-2">
            {{ $t("macroStatus") }}
          </th>
          <th class="heading-2 px-2" width="120px">
            {{ $t("date") }}
          </th>
          <th class="heading-2 px-2">
            {{ $t("orderViewPage.message") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(checkpoint, index2) in volumeCheckpoints[index].checkpoints"
          class="bg-gray-10 body-tr"
          :key="index2"
        >
          <td class="px-2 text-left">
            <div class="card border-0 m-1">
              <img
                src="../../../assets/img/nobordist-management.png"
                width="100%"
                class="rounded"
              />
            </div>
          </td>
          <td class="px-2 text-left">
            <div v-if="checkpoint.checkpoint">
              <NbBadge
                class="fit-content"
                :type="getStatusType(checkpoint.checkpoint.name)"
                :text="checkpoint.checkpoint.description"
              />
            </div>
          </td>
          <td class="px-2 text-left body-2">
            {{ volume.last_mile_tracking_number }}
          </td>
          <td class="px-2 text-left body-2">
            {{ checkpoint.checkpoint.name }}
          </td>
          <td class="px-2 text-left body-2">
            <div v-if="checkpoint.date_iso">
              <div>
                {{ checkpoint.date_iso.substring(0, 10) }}
              </div>
              <div>
                {{ checkpoint.date_iso.substring(11, 16) }}
              </div>
            </div>
          </td>

          <td class="px-2 text-left body-2">
            {{ checkpoint.message }}
          </td>

          <!--
          <td class="text-secondary align-middle text-left">
              <button class="btn btn-success">Download</button>
            </td>
          -->
        </tr>
        <!-- 
        <tr v-if="!editableCheckpointsHidden" style="background-color: #eef4f9">
          <td class=" bg-green align-middle  text-left">
            {{ $t("orderViewPage.statusToAdd") }}
          </td>
          <td class=" align-middle  text-left">
            <span
              v-if="
                statusToAdd[index].name === 'PENDING' ||
                statusToAdd[index].name === 'FAILED_ATTEMPT' ||
                statusToAdd[index].name === 'EXCEPTION IN PICKUP'
              "
              class="badge badge-warning ml-1"
              >{{ statusToAdd[index].name }}</span
            >
            <span
              v-else-if="
                statusToAdd[index].name === 'PARTIAL DELIVERY FAILED' ||
                statusToAdd[index].name === 'EXPIRED' ||
                statusToAdd[index].name === 'EXCEPTION'
              "
              class="badge badge-danger ml-1"
              >{{ statusToAdd[index].name }}</span
            >
            <span v-else class="badge badge-success ml-1">{{
              statusToAdd[index].name
            }}</span>
            <h6 class="m-0">
              {{ statusToAdd[index].title }}
            </h6>
          </td>
          <td class=" align-middle  text-left">
            {{ statusToAdd[index].description }}
          </td>
          <td></td>
          <td></td>
          <td class=" align-middle  text-left">
            <button
              @click="updateStatus(index, volume)"
              class="button-success ml-3"
            >
              {{ $t("orderViewPage.addStatus") }}
            </button>
          </td>
        </tr>
        -->
      </tbody>
    </table>
  </div>
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";

export default {
  components: { NbBadge },
  name: "TrackingHistoryTable",
  props: {
    volume: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    volumeCheckpoints: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editableCheckpointsHidden: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    getStatusType(status) {
      let finalType = "success";
      if (!status) {
        return;
      }
      if (
        status == "PENDING" ||
        status == "FAILED_ATTEMPT" ||
        status == "EXCEPTION IN PICKUP"
      ) {
        finalType = "warning";

        return finalType;
      }

      if (
        status == "PARTIAL DELIVERY FAILED" ||
        status == "EXPIRED" ||
        status == "EXCEPTION"
      ) {
        finalType = "danger";

        return finalType;
      }

      return finalType;
    },
  },
};
</script>

<style scoped>
.min-w-200 {
  min-width: 9rem;
}
.items-table thead tr th {
  padding-bottom: 0.5rem;
}
.items-table .body-tr {
  border-radius: 0.25rem;
}
.items-table .body-tr td {
  padding: 0.9rem 0.7rem;
  border-bottom: 0.25rem solid var(--gray-05);
}
</style>
