<template>
  <div>
    <NbCard id="general-card-wraper" class="bg-gray-05 mt-4 mb-0">
      <div class="heading-3 w-fit px-4 py-2 mb-2">
        {{ $t("orderViewPage.fieldsOrderCosts.general_costs") }}
      </div>
      <div class="hr-top mb-0">
        <NbTablev2
          v-if="generalCostsTable"
          tableOf="generalCostsTable"
          :hasParentItens="true"
          ref="generalCostsTable"
          tdClass="td-dark"
          :hiddeTableOptions="true"
          height="fit-content"
          :allFields="generalCostFields"
          :fields="fields"
          @reloadFields="fields = $event"
          :startItems="generalCostsTable"
        >
          <template #cell(general_costs)="data">
            <span
              class="link-1"
              data-toggle="modal"
              data-target="#modal-general-costs"
            >
              {{ data.item.general_costs }}
            </span>
          </template>
        </NbTablev2>
      </div>
    </NbCard>
    <NbModal
      id="modal-general-costs"
      modalConfig="modal-dialog-centered"
      :hiddenCloseBtn="true"
    >
      <template v-slot:header>
        <div class="d-flex justify-content-between mb-4">
          <h5 class="heading-4">
            {{ $t("orderViewPage.fieldsOrderCosts.general_costs") }}
          </h5>
        </div>
      </template>
      <div class="mb-3">
        <NbTablev2
          v-if="order && order?.order_costs?.length > 0"
          tableOf="order_costs-table"
          :hasParentItens="true"
          ref="order_costs-table"
          :hiddeTableOptions="true"
          height="fit-content"
          :allFields="orderCostsFields"
          :fields="fieldsOrderCosts"
          @reloadFields="fieldsOrderCosts = $event"
          :startItems="order.order_costs"
        >
          <template v-slot:cell(value)="data">
            <div>
              {{ standardizeCurrencyValue(data.item.value) }}
            </div>
          </template>
          <template v-slot:cell(created_at)="data">
            <div>
              {{ formateDate(data.item.created_at, data.item.created_at) }}
            </div>
          </template>
        </NbTablev2>
        <div v-else>No order costs found</div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            @click="$helpers.openModal('modal-general-costs')"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    NbCard,
    NbTablev2,
    NbModal,
    NbButton,
  },
  name: "GeneralCostsCardView",
  props: {
    generalCostsTable: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      measurementSystem: "metric",
      fields: [],
      fieldsOrderCosts: [],
      macroStatusList: [],
      statusToAdd: [{}],
    };
  },
  created() {
    this.fields = this.generalCostFields;
  },
  computed: {
    generalCostFields() {
      return [
        {
          key: "freight_cost",
          label: this.$t("orderViewPage.fieldsOrderCosts.freight_cost"),
        },
        {
          key: "duties",
          label: this.$t("orderViewPage.fieldsOrderCosts.duties"),
        },
        {
          key: "insurance",
          label: this.$t("orderViewPage.fieldsOrderCosts.insurance"),
        },
        {
          key: "general_costs",
          label: this.$t("orderViewPage.fieldsOrderCosts.general_costs"),
        },
        {
          key: "fines",
          label: this.$t("orderViewPage.fieldsOrderCosts.fines"),
        },
      ];
    },
    orderCostsFields() {
      return [
        { key: "name", label: "Type" },
        { key: "value", label: "Value" },
        { key: "created_at", label: "Creation Date" },
      ];
    },
    currentWeightMetric() {
      if (this.measurementSystem === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentMeasurementMetric() {
      if (this.measurementSystem === "metric") {
        return "cm";
      }
      return "in";
    },
  },
  methods: {
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear()
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    simplifyCurrency() {
      if (this.order.currency === "USD") {
        return "$";
      } else {
        return this.order.currency;
      }
    },
    standardizeCurrencyValue(val) {
      if (typeof val === "number" || typeof val === "string") {
        return this.simplifyCurrency() + parseFloat(val).toFixed(2);
      }
      return "-";
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
  },
};
</script>

<style scoped>
.box-circle-wrapper {
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 50%;
  text-align: center;
}
.box-icon {
  font-size: 2.1rem;
  line-height: 4.5rem;
  margin: auto;
}
.hr-top {
  border-top: 1px solid var(--gray-10);
  padding-top: 0.5rem;
}
</style>
