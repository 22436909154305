<template>
  <div
    class="nb-input"
    :class="errorsFeedback ? `is-invalid ${variant}` : `${variant}`"
  >
    <label
      v-if="name"
      class="input-label"
      :class="disabled ? 'not-allowed' : ''"
      :for="id"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
        <a
          v-if="helpTextLink"
          :href="helpTextLink.href"
          target="_blank"
          class="link-2"
          style="color: white !important"
        >
          {{ helpTextLink.text }}
        </a>
      </NbHelpText>
    </label>
    <div
      :id="`${id}-input-wrapper`"
      class="d-flex input-wrapper"
      :class="wrapperClasses"
    >
      <div
        v-if="prepend"
        class="fake-input pointer prepend"
        :class="`
          ${textContent && !disabled ? 'text-black ' : ''}
          ${errorsFeedback ? 'is-invalid ' : ''}
          ${disabled ? 'disabled' : ''}
        `"
      >
        {{ prepend }}
      </div>
      <div
        class="nb form-control input"
        :class="`${
          errorsFeedback
            ? disabled
              ? 'is-invalid not-allowed'
              : 'is-invalid'
            : disabled
            ? 'not-allowed'
            : ''
        }`"
        :id="id"
        :disabled="disabled"
      >
        <slot>
          <span :class="{ 'text-gray-60': !textContent }">
            {{ textContent || placeholder }}
          </span>
        </slot>
      </div>

      <div
        v-if="append"
        class="fake-input pointer append"
        :class="` 
          ${textContent ? 'text-black ' : ''}
          ${errorsFeedback ? 'is-invalid' : ''}
        `"
      >
        {{ append }}
      </div>
    </div>
    <ErrorFeedback :error="errorsFeedback" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbTextField",
  components: {
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    helpTextLink: {
      type: Object,
      required: false,
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      textContent: this.value,
      internalErrors: [],
      isFocused: false,
    };
  },
  methods: {},
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error[0];
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors[0];
      }
      return "";
    },
    wrapperClasses() {
      if (this.disabled) {
        return "disabled ";
      }

      if (this.errorsFeedback) {
        return "is-invalid ";
      }

      if (this.textContent || this.isFocused) {
        return "is-typeing ";
      }
      return "";
    },
  },
  watch: {
    textContent(val) {
      this.$emit("input", val);
    },
    value(newValue) {
      this.textContent = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-input {
  position: relative;
}
//inputs
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
.input-wrapper {
  border-bottom: 2px solid var(--gray-40);
}
.input-wrapper.is-typeing.disabled,
.input-wrapper.disabled {
  border-bottom: 2px solid var(--gray-20);
}
.input-wrapper.is-typeing {
  border-bottom: 2px solid var(--black);
}
.input-wrapper.is-typeing.is-invalid,
.input-wrapper.is-invalid {
  border-bottom: 2px solid var(--error) !important;
}
.input-wrapper.is-typeing.is-valid,
.input-wrapper.is-valid {
  border-bottom: 2px solid var(--success);
}
.form-control.input {
  transition: all 0.3s ease;
  height: 2.37rem;
  padding: 9px 12px;
  box-shadow: none;
  border: none;
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box !important;
  border-radius: 4px 4px 0px 0px;
}
.form-control.input:hover {
  //color: var(--gray-40);
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}
.form-control.input:focus {
  box-shadow: none;
}
.form-control.input::placeholder {
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  caret-color: var(--error);
  background-image: none;
}

.input-wrapper.is-valid .fake-input,
.input-wrapper.is-valid .form-control.input {
  caret-color: var(--success);
  background-image: none;
}
.nb-input:not(:has(:disabled)) .input-wrapper:hover .fake-input,
.form-control.input.is-invalid:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}

.input-wrapper .fake-input.disabled,
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-05) !important;
  color: var(--gray-20) !important;
}

.nb-input .nb.form-control.prepend-padding {
  padding-left: 2rem !important;
}
.nb-input .fake-input {
  color: var(--gray-40);
  transition: all 0.3s ease;
  height: 2.37rem;
  padding: 0.56rem 0.37rem;
  box-shadow: none;
  border: none;
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 0px;
}
/* .nb-input .fake-input.is-invalid:not(:placeholder-shown) {
  //border-bottom: 2px solid var(--black);
} */

.nb-input .fake-input.is-invalid {
  caret-color: var(--error);
  background-image: none;
}
.nb-input .prepend-text {
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  left: 0.8rem;
  color: var(--gray-40);
}

.text-black {
  color: #495057 !important;
}

.nb-input .nb.form-control.append-padding {
  padding-right: 2rem !important;
}
.nb-input .append-text {
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  right: 0.8rem;
  color: var(--gray-40);
}

.text-black {
  color: #495057 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.nb-input:has(.prepend) .input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nb-input:has(.append) .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* borderless input */
.borderless.nb-input .input-wrapper.is-typeing.disabled,
.borderless.nb-input .input-wrapper.disabled,
.borderless.nb-input .input-wrapper.is-typeing,
.borderless.nb-input .input-wrapper {
  background: var(--gray-20) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
}

.borderless.nb-input .input-wrapper .fake-input.disabled,
.borderless.nb-input .form-control.input:disabled,
.borderless.nb-input .form-control.input[disabled],
.borderless.nb-input .form-control.input {
  background: var(--gray-20) !important;
  color: var(--black) !important;
}

/* borderless-gray-10 input */
.borderless-gray-10.nb-input .input-wrapper.is-typeing.disabled,
.borderless-gray-10.nb-input .input-wrapper.disabled,
.borderless-gray-10.nb-input .input-wrapper.is-typeing,
.borderless-gray-10.nb-input .input-wrapper {
  background: var(--gray-10) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
}

.borderless-gray-10.nb-input .input-wrapper .fake-input.disabled,
.borderless-gray-10.nb-input .form-control.input:disabled,
.borderless-gray-10.nb-input .form-control.input[disabled],
.borderless-gray-10.nb-input .form-control.input {
  background: var(--gray-10) !important;
  color: var(--black) !important;
}
.borderless-gray-10.nb-input .input-wrapper .prepend {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
  padding-right: 0px;
  padding-left: 0.75rem;
}
</style>
