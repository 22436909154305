<template>
  <div>
    <NbCard
      id="boxes-card-wraper"
      class="bg-gray-05 mt-4 mb-0 py-0"
      v-for="(volume, index) in order.volumes"
      :key="index"
    >
      <div class="heading-3 text-center bg-gray-10 w-fit px-4 py-2 mb-4">
        {{ $t("box") }} {{ addZero(index + 1) }}
      </div>
      <div class="mb-4 heading-3">{{ $t("orderViewPage.boxData") }}</div>
      <div class="d-flex justify-content-between mb-4">
        <div class="bg-gray-10 box-circle-wrapper mt-2 mr-3">
          <i class="fas fa-box box-icon"></i>
        </div>
        <div class="d-flex justify-content-between flex-wrap mb-4">
          <NbTextField
            :id="`box-width-${index}`"
            class="box-text-field w-16 mb-3"
            :name="$t('width')"
            variant="borderless-gray-10"
          >
            {{ standardizeValue(volume.width) }}
            {{ currentMeasurementMetric }}
          </NbTextField>
          <NbTextField
            :id="`box-height-${index}`"
            class="box-text-field w-16 mb-3"
            :name="$t('height')"
            variant="borderless-gray-10"
          >
            {{ standardizeValue(volume.height) }}
            {{ currentMeasurementMetric }}
          </NbTextField>
          <NbTextField
            :id="`box-length-${index}`"
            class="box-text-field w-16 mb-3"
            :name="$t('length')"
            variant="borderless-gray-10"
          >
            {{ standardizeValue(volume.length) }}
            {{ currentMeasurementMetric }}
          </NbTextField>
          <NbTextField
            :id="`box-weight-${index}`"
            class="box-text-field w-16 mb-3"
            :name="$t('weight')"
            variant="borderless-gray-10"
          >
            {{ standardizeValue(volume.weight) }}
            {{ currentWeightMetric }}
          </NbTextField>
          <NbTextField
            :id="`box-cubed-weight-${index}`"
            class="box-text-field w-16 mb-3"
            :name="$t('cubedWeight')"
            variant="borderless-gray-10"
          >
            {{ standardizeValue(volume.cubed_weight) }}
            kg
          </NbTextField>
          <NbTextField
            :id="`lastMileTrackingNumber-${index}`"
            class="box-text-field w-38"
            :name="$t('lastMileTrackingNumber')"
            variant="borderless-gray-10"
          >
            {{ volume.last_mile_tracking_number }}
          </NbTextField>
          <NbTextField
            :id="`box-firstMileTrackingNumber-${index}`"
            class="box-text-field w-38"
            :name="$t('firstMileTrackingNumber')"
            variant="borderless-gray-10"
          >
            {{ volume.first_mile_tracking_number }}
          </NbTextField>
          <NbTextField
            :id="`box-totalItems-${index}`"
            class="box-text-field w-20"
            :name="$t('totalItems')"
            variant="borderless-gray-10"
          >
            {{ totalItems(volume.order_items) }}
          </NbTextField>
        </div>
        <div class="mb-4 w-26 pt-4 ml-2">
          <NbButton
            class="align-self-end mb-2 mx-1 w-100"
            variant="secondary"
            @click="generateBoxLabel(volume.id)"
          >
            {{ $t("orderViewPage.generateBoxLabel") }}
          </NbButton>
          <NbButton
            class="align-self-end mb-2 mx-1 w-100"
            variant="secondary"
            @click="generateVolumeFirstMile(volume.id)"
          >
            {{ $t("ordersListPage.firstMileLabel") }}
          </NbButton>
          <NbButton
            v-if="contract.id == '2' || contract.id == '3'"
            class="align-self-end mb-2 mx-1 w-100"
            variant="secondary"
            @click="generateCommercialInvoice(order.id, volume.id)"
          >
            {{ $t("ordersListPage.invoice") }}
          </NbButton>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap mb-4">
        <NbTextField
          :id="`box-collectedIcms-${index}`"
          class="box-text-field w-38"
          :name="$t('collectedIcms')"
          variant="borderless-gray-10"
          v-if="order.prc"
        >
          {{ standardizeValue(volume.collected_icms) }}
        </NbTextField>
        <NbTextField
          :id="`box-collectedIi-${index}`"
          class="box-text-field w-30"
          :name="$t('collectedIi')"
          variant="borderless-gray-10"
          v-if="order.prc"
        >
          {{ standardizeValue(volume.collected_ii) }}
        </NbTextField>
        <NbTextField
          :id="`box-generalDescription-${index}`"
          class="box-text-field w-30"
          :name="$t('generalDescription')"
          variant="borderless-gray-10"
          v-if="order.prc"
        >
          {{ volume.general_description }}
        </NbTextField>
      </div>
      <div class="hr-top mb-4">
        <NbTablev2
          tableOf="volumeItems"
          :hasParentItens="true"
          ref="volumeItems"
          tdClass="td-dark"
          :hiddeTableOptions="true"
          height="fit-content"
          :allFields="allFields"
          :fields="fields"
          @reloadFields="fields = $event"
          :startItems="volume.order_items"
        >
          <template #cell(unit_products_value)="data">
            {{ standardizeCurrencyValue(data.item.unit_products_value) }}
          </template>
          <template #cell(unit_freight_value)="data">
            {{ standardizeCurrencyValue(data.item.unit_freight_value) }}
          </template>
          <template #cell(unit_tax_value)="data">
            {{ standardizeCurrencyValue(data.item.unit_tax_value) }}
          </template>
        </NbTablev2>
      </div>
      <div class="hr-top mb-2">
        <div class="heading-3 mb-4">
          {{ $t("orderViewPage.trackingHistory") }}
        </div>
        <TrackingHistoryTable
          :index="index"
          :volume="volume"
          :volumeCheckpoints="volumeCheckpoints"
        />
      </div>

      <div class="d-flex justify-content-between flex-wrap mb-4">
        <div class="body-2 w-50 text-right mt-2 pt-1">
          {{ statusToAdd[index] ? statusToAdd[index].description : "" }}
        </div>
        <NbSelectInput
          :id="`select-input-statusToAdd-${index}`"
          class="w-30"
          name=""
          :options="macroStatusList"
          v-model="statusToAdd[index]"
        />
        <NbButton
          :disabled="isLoading"
          class="w-15 mt-1"
          size="sm"
          variant="secondary"
          @click="updateStatus(index, volume)"
        >
          {{ $t("orderViewPage.addStatus") }}
        </NbButton>
      </div>
    </NbCard>
  </div>
</template>

<script>
import NProgress from "nprogress";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextField from "@/components/input/text/NbTextField.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import TrackingHistoryTable from "./TrackingHistoryTable.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";

import UsersService from "@/services/UsersService";
import OrderLabelService from "@/services/OrderLabelService";
import CheckpointService from "@/services/CheckpointService";

const usersService = new UsersService();
const orderLabelService = new OrderLabelService();
const checkpointService = new CheckpointService();

export default {
  components: {
    NbCard,
    NbTextField,
    NbButton,
    NbTablev2,
    TrackingHistoryTable,
    NbSelectInput,
  },
  name: "BoxesCardView",
  props: {
    order: {
      type: Object,
      required: true,
    },
    volumeCheckpoints: {
      type: Array,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    /*
    overpack: {
      type: Object,
      required: true,
    }, */
  },
  data() {
    return {
      measurementSystem: "metric",
      fields: [],
      macroStatusList: [],
      statusToAdd: [{}],
      isLoading: false,
    };
  },
  created() {
    this.getStatusOptions();
    this.fields = this.allFields;
    usersService.getUserMeasurementSystem().then(({ data }) => {
      this.measurementSystem = data.data;
    });
  },
  computed: {
    allFields() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "sku", label: "SKU" },
        { key: "hs_code", label: this.$t("hsCode") },
        {
          key: "unit_products_value",
          label: this.$t("orderViewPage.fieldsVolumeItems.unitProducts"),
        },
        {
          key: "unit_freight_value",
          label: this.$t("orderViewPage.fieldsVolumeItems.unitFreight"),
        },
        {
          key: "unit_tax_value",
          label: this.$t("orderViewPage.fieldsVolumeItems.unitTax"),
        },
        { key: "quantity", label: this.$t("quantity") },
        { key: "description", label: this.$t("description") },
      ];
    },
    currentWeightMetric() {
      if (this.measurementSystem === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentMeasurementMetric() {
      if (this.measurementSystem === "metric") {
        return "cm";
      }
      return "in";
    },
  },
  methods: {
    totalItems(order_items) {
      let total = 0;
      order_items.forEach((item) => {
        total += Number(item.quantity);
      });
      return total;
    },
    updateStatus(index, volume) {
      if (!this.statusToAdd[index].tracking_code) {
        return;
      }
      const params = {
        checkpoint_code: parseInt(this.statusToAdd[index].tracking_code),
      };
      this.isLoading = true;
      checkpointService
        .addCheckpointToVolume(volume.id, params)
        .then((response) => {
          this.isLoading = false;
          this.$helpers.toast("Status updated", "success");
          this.volumeCheckpoints[index].checkpoints.push(response.data.data);
          this.order.volumes[index].current_status = this.statusToAdd[index];
          this.statusToAdd[index] = {};
        })
        .catch((error) => {
          this.isLoading = false;
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    getStatusOptions() {
      checkpointService.getMacros().then((response) => {
        this.macroStatusList = response.data.data;
        this.macroStatusList = this.macroStatusList
          .filter((elt) => {
            return !(
              elt.name == "PENDING" ||
              elt.name == "NEW" ||
              elt.name == "PARTIAL DELIVERY" ||
              elt.name == "PARTIAL DELIVERY FAILED"
            );
          })
          .map(({ tracking_code, name, description }) => ({
            value: { tracking_code, name, description },
            text: name,
            description: description,
          }));

        this.macroStatusList.push({
          value: null,
          text: this.$t("orderStatus.selectOption"),
          description: "",
        });
      });
    },
    generateCommercialInvoice(orderId, volumeId = {}) {
      orderLabelService
        .generateCommercialInvoiceLabel(orderId, volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },
    generateVolumeFirstMile(volumeId) {
      orderLabelService
        .generateVolumeFirstMile(volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateBoxLabel(volumeId) {
      orderLabelService
        .generateVolumeLabel(volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    simplifyCurrency() {
      if (this.order.currency === "USD") {
        return "$";
      } else {
        return this.order.currency;
      }
    },
    standardizeCurrencyValue(val) {
      if (typeof val === "number" || typeof val === "string") {
        return this.simplifyCurrency() + parseFloat(val).toFixed(2);
      }
      return "-";
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
  },
};
</script>

<style scoped>
.box-circle-wrapper {
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 50%;
  text-align: center;
}
.box-icon {
  font-size: 2.1rem;
  line-height: 4.5rem;
  margin: auto;
}
.hr-top {
  border-top: 1px solid var(--gray-10);
  padding-top: 1rem;
}
</style>
